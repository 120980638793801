export default function helpMenu() {

  $('.btn-close-help').on('click', function(e) {
    e.preventDefault();
    $('.help-panel').animate({right:"-205px"}, 200 , function() {
      $(this).find('.help-actions a').toggleClass('open');
    });
  });

  $('.btn-open-help').on('click', function(e) {
    e.preventDefault();
    $('.help-panel').animate({right:"0"}, 200 , function() {
      $(this).find('.help-actions a').toggleClass('open');
    });
  });

}