'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;

require('bootstrap');
const enquire = require('enquire.js');

import './css/app.scss';

import adminMenu from '@elasticms/admin-menu';
import back2top from "./js/back2top";
import ajaxSearch from "./js/ajax-search";
import toc from "./js/toc";
import externalLink from "./js/external-link";
import helpMenu from "./js/help-menu";
import form from "./js/form";
import {cookiesBar} from "./js/cookiesBar";

adminMenu();
back2top();
ajaxSearch();
toc();
externalLink();
helpMenu();
form();
cookiesBar.init('#cookiesBanner')

console.log('Demo website loaded!');

$(function() {
  const $language = $('html').attr('lang');

  // move search on mobile
  const $mainSearch = $('#main-search');

  enquire.register("screen and (max-width:767.98px)", {
    match : function() {
      $mainSearch.detach();
      $('#mm-1 .mm-listview').before($mainSearch);
    },
    unmatch : function() {
      $mainSearch.detach();
      $('.navbar-right').prepend($mainSearch);
    }
  });

  // add collapse on mobile HP
  const $themeTitles = $('#themes h2');
  const $themeLinks = $('#themes a[data-toggle="collapse"]');

  if($themeTitles.length > 0) {
    enquire.register("screen and (min-width:768px)", {
      match : function() {
        $themeTitles.each(function( index ) {
          if ($(this).parent().is('a')) {
            $(this).unwrap();
          }
        });
      },
      unmatch : function() {
        $themeTitles.each(function( index ) {
          if ($(this).parent().is(':not(a)')) {
            $(this).wrap($themeLinks[index]);
          }
        });
      }
    });
  }

  $(document).on('change', '.language-select', function() {
    document.location.href = $(this).val();
  });
});
